// 将URL参数分割为对象键值对
// const getParam = () => {
//     let routes = getCurrentPages(); // 获取当前打开过的页面路由数组
//     let curRoute = routes[routes.length - 1].route //获取当前页面路由
//     let curParam = routes[routes.length - 1].options; //获取路由参数
//     // 拼接参数
//     let param = ''
//     for (let key in curParam) {
//         param += '&' + key + '=' + curParam[key]
//     }

//     // 把参数保存为对像
//     let obj = {}
//     for (let key in curParam) {
//         obj[key] = curParam[key]
//     }
//     return obj
// }
const getQueryString = () => {
    let url = location.href; //获取url中"?"符后的字串
    let theRequest = {};
    if (url.indexOf('?') != -1) {
        let str = url.substr(url.indexOf('?') + 1);
        let strs = str.split('&');
        for (let i = 0; i < strs.length; i++) {
            theRequest[strs[i].split('=')[0]] = unescape(strs[i].split('=')[1]);
        }
    }
    return theRequest;
}
const convertCurrency = (money) => {
    //汉字的数字
    var cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖');
    //基本单位
    var cnIntRadice = new Array('', '拾', '佰', '仟');
    //对应整数部分扩展单位
    var cnIntUnits = new Array('', '万', '亿', '兆');
    //对应小数部分单位
    var cnDecUnits = new Array('角', '分', '毫', '厘');
    //整数金额时后面跟的字符
    var cnInteger = '整';
    //整型完以后的单位
    var cnIntLast = '元';
    //最大处理的数字
    var maxNum = 999999999999999.9999;
    //金额整数部分
    var integerNum;
    //金额小数部分
    var decimalNum;
    //输出的中文金额字符串
    var chineseStr = '';
    //分离金额后用的数组，预定义
    var parts;
    // 传入的参数为空情况 
    console.log(money)
    if (money == '') {
        return '';
    }
    money = parseFloat(money)
    if (money >= maxNum) {
        return ''
    }
    // 传入的参数为0情况 
    if (money == 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger;
        return chineseStr
    }
    // 转为字符串
    money = money.toString();
    // indexOf 检测某字符在字符串中首次出现的位置 返回索引值（从0 开始） -1 代表无
    if (money.indexOf('.') == -1) {
        integerNum = money;
        decimalNum = ''
    } else {
        parts = money.split('.');
        integerNum = parts[0];
        decimalNum = parts[1].substr(0, 4);
    }
    //转换整数部分
    if (parseInt(integerNum, 10) > 0) {
        let zeroCount = 0;
        let IntLen = integerNum.length
        for (let i = 0; i < IntLen; i++) {
            let n = integerNum.substr(i, 1);
            let p = IntLen - i - 1;
            let q = p / 4;
            let m = p % 4;
            if (n == '0') {
                zeroCount++;
            } else {
                if (zeroCount > 0) {
                    chineseStr += cnNums[0]
                }
                zeroCount = 0;
                chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
            }
            if (m == 0 && zeroCount < 4) {
                chineseStr += cnIntUnits[q];
            }
        }
        // 最后+ 元
        chineseStr += cnIntLast;
    }
    // 转换小数部分
    if (decimalNum != '') {
        let decLen = decimalNum.length;
        for (let i = 0; i < decLen; i++) {
            let n = decimalNum.substr(i, 1);
            if (n != '0') {
                chineseStr += cnNums[Number(n)] + cnDecUnits[i]
            }
        }
    }
    if (chineseStr == '') {
        chineseStr += cnNums[0] + cnIntLast + cnInteger;
    } else if (decimalNum == '') {
        chineseStr += cnInteger;
    }

    return chineseStr
}

//base64图片转成文件流格式
const base64ToFile = (data, fileName) => {
    const dataArr = data.split(",");
    const byteString = atob(dataArr[1]);
    const options = {
        type: "image/jpeg",
        endings: "native"
    };
    const u8Arr = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        u8Arr[i] = byteString.charCodeAt(i);
    }
    let formData = new FormData();
    let fileOfBlob = new File([u8Arr], fileName + ".jpg", options);//返回文件流
    formData.append("file", fileOfBlob);
    return formData
}

const base64toFile = (base64Data, fileName) => {
    var arr = base64Data.split(',');
    var mime = arr[0].match(/:(.*?);/)[1];
    var bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
}

//base64转成blob 
const dataURLtoBlob = (dataURI) => {
    let binary = atob(dataURI.split(",")[1]);
    let array = [];
    for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: 'image/png' });
}
// 时间戳转年月日时分秒
const timeStampToYMD = (timeStamp) => {
    var time = new Date(timeStamp);
    var y = time.getFullYear();//返回年份
    var M = time.getMonth() + 1;
    var d = time.getDate();
    var h = time.getHours();
    var m = time.getMinutes();
    var s = time.getSeconds();
    if (M <= 9) {
        M = '0' + M
    }
    if (d <= 9) {
        d = '0' + d
    }
    if (h <= 9) {
        h = '0' + h
    }
    if (m <= 9) {
        m = '0' + m
    }
    if (s <= 9) {
        s = '0' + s
    }
    var times = y + '-' + M + '-' + d
    return times
}
export {
    getQueryString,
    convertCurrency,
    base64ToFile,
    dataURLtoBlob,
    base64toFile,
    timeStampToYMD
}