import axios from "axios"
export default {
	common: {
		baseUrl: "https://fengyisuan.com/app"
	},
	request(options = {}) {
		return new Promise((resolve) => {
			axios({
				url: this.common.baseUrl + options.url,
				method: options.method,
				dataType: "json",
				data: options.data || {}
				})
				.then(function(data) {
					resolve(data.data)
				});
		})
	}
}